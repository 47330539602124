import React, { useEffect, useContext, useRef } from 'react';

import Marquee from '../components/marquee';
import Feedback from '../components/feedback';
import Page from '../components/page';
import SEO from '../components/seo';
import Matter from 'matter-js';

import { PageWrapperContext } from '../components/page-wrapper';
import { useWindowSize } from '../components/window-size';

import styles from '../styles/pages/about.module.scss';

const AboutPage = ( { transitionStatus } ) => {

    const pageWrapperContext = useContext( PageWrapperContext );
    const windowSize = useWindowSize();

    const Engine = Matter.Engine;
    const Render = Matter.Render;
    const World = Matter.World;
    const Bodies = Matter.Bodies;

    const sceneRef = useRef();
    const renderRef = useRef();
    const engineRef = useRef();

    useEffect( () => {

        pageWrapperContext.setPageClass( 'page-about' );

        engineRef.current = Engine.create( {} );
        renderRef.current = Render.create( {
            element: sceneRef.current,
            engine: engineRef.current,
            options: {
                width: windowSize.width,
                height: windowSize.height,
                wireframes: false,
                background: 'transparent',
                // pixelRatio: 2 // Bad impact on performance
                pixelRatio: 1
            }
        } );

        addBodies();

        Engine.run( engineRef.current );
        Render.run( renderRef.current );

        return () => {
            Matter.Render.stop( renderRef.current );
            Matter.World.clear( engineRef.current.world );
            Matter.Engine.clear( engineRef.current );
            Matter.Runner.stop( engineRef.current );
            renderRef.current.canvas.remove();
            renderRef.current.canvas = null;
            renderRef.current.context = null;
            renderRef.current.textures = {};
            engineRef.current.events = {};
        };

    }, [] );

    const addBodies = () => {

        let shapes = [
            { type: 'circle', sizeFactor: .9, color: '#00aeef' },
            { type: 'polygon', sizeFactor: 1, sides: 3, color: '#006838' },
            { type: 'polygon', sizeFactor: 1, sides: 4, color: '#ef7d00' },
            { type: 'polygon', sizeFactor: .9, sides: 7, color: '#f9b202' },
        ];

        shapes = shuffle( shapes );

        shapes.map( ( shape, index ) => {

            let body, bodySize, forceX, forceR, x, y;

            if ( windowSize.width > windowSize.height ) {

                bodySize = windowSize.width / 7;
                forceX = -.5;
                forceR = .1;

                if ( index === 0 ) {
                    x = windowSize.width / 8 * 4;
                    y = windowSize.height / 8 * 2;
                }

                if ( index === 1 ) {
                    x = windowSize.width / 8 * 6;
                    y = windowSize.height / 8 * 2;
                }

                if ( index === 2 ) {
                    x = windowSize.width / 8 * 3;
                    y = windowSize.height / 8 * 6;
                }

                if ( index === 3 ) {
                    x = windowSize.width / 8 * 6;
                    y = windowSize.height / 8 * 6;
                }

            } else {

                bodySize = windowSize.height / 6;
                forceX = -.2;
                forceR = .1;

                x = ( Math.random() * windowSize.width / 2 ) + ( windowSize.height / 4 );
                y = ( windowSize.height / 4 * index ) + ( windowSize.height / 8 );

            }

            const options = {
                restitution: .5,
                friction: .1,
                force: { x: forceX }
            };

            if ( shape.type === 'circle' ) {
                body = Bodies.circle( x, y, bodySize * shape.sizeFactor, { ...options, render: { fillStyle: shape.color, } } );
            } else if ( shape.type === 'polygon' ) {
                body = Bodies.polygon( x, y, shape.sides, bodySize * shape.sizeFactor, { ...options, render: { fillStyle: shape.color, } } );
            }

            Matter.Body.setAngularVelocity( body, forceR );
            World.add( engineRef.current.world, body );

        } );

        const wallLeftX = ( windowSize.width > windowSize.height ) ? windowSize.width / 8 : -5;
        const wallRightX = ( windowSize.width > windowSize.height ) ? windowSize.width / 8 * 7 : windowSize.width + 5;

        World.add( engineRef.current.world, [
            // wall left
            Bodies.rectangle( wallLeftX, windowSize.height / 2, 10, windowSize.height, { isStatic: true, render: { fillStyle: 'transparent' } } ),
            // wall right
            Bodies.rectangle( wallRightX, windowSize.height / 2, 10, windowSize.height, { isStatic: true, render: { fillStyle: 'transparent' } } ),
            // floor
            Bodies.rectangle( windowSize.width / 2, windowSize.height + 5, windowSize.width, 10, { isStatic: true, render: { fillStyle: 'transparent' } } ),
            //Ceiling
            Bodies.rectangle( windowSize.width / 2, -windowSize.height / 2, windowSize.width, 10, { isStatic: true, render: { fillStyle: 'transparent' } } ),
        ] );

    };

    const handleResize = () => {
        World.clear( engineRef.current.world );
        engineRef.current.events = {};
        addBodies();
        renderRef.current.canvas.width = windowSize.width;
        renderRef.current.canvas.height = windowSize.height;
        Render.setPixelRatio( renderRef.current, 1 );
    };

    useEffect( () => {
        handleResize();
    }, [ windowSize, pageWrapperContext.pageHeight ] );

    return <Page transitionStatus={transitionStatus}>
        <SEO title="About" description="This playbook is designed for all journalists who are interested in deepening their knowledge of cross-border journalism. The playbook is a result of cross-border collaboration itself: 16 journalists across Europe and several guest authors share their insights and experiences with cross-border collaborations." image={'/images/opengraph/general.jpg'} />
        <div className={styles.toys}><div ref={sceneRef} /></div>
        <section className={`container-fluid ${styles.body}`}>
            <div className={`row ${styles.title}`}>
                <div className="col col-12">
                    <h2>This playbook is designed for all journalists who are interested in deepening their knowledge of cross-border journalism. The playbook is a result of cross-border collaboration itself: 16 journalists across Europe and several guest authors share their insights and experiences with cross-border collaborations.</h2>
                </div>
            </div>
            <div className={`row ${styles.description}`}>
                <div className="col col-6 col-xs-12">
                    <p>By providing tips and tricks on how to plan and manage your team work, overcome conflicts, solve problems, find back-up plans and produce your story, we hope to support journalists embarking on a cross-border project.</p>
                </div>
                <div className="col col-6 col-xs-12">
                    <p>It is important to note that this playbook is far from complete: cross-border collaboration is an increasingly dynamic and complex journalistic discipline and we were unable to cover everything about it. Instead, this playbook aims to serve as a useful resource for journalists interested in learning from the experience of other cross-border journalists.</p>
                </div>
            </div>
        </section>
        <section className={`${styles.marquee}`}>
            <Marquee speed="3s"><h3>Project Team</h3></Marquee>
        </section>
        <section className="container-fluid">
            <div className={`row ${styles.projectTeam}`}>
                <div className="col-9 col-md-10 col-xs-12">
                    <p>This playbook is an initiative of <a href="https://n-ost.org/" rel="noopener noreferrer nofollow">n-ost</a> and celebrates a great number of cross-border projects that have been completed by fellows of their <a href="https://n-ost.org/77-reporters-in-the-field" rel="noopener noreferrer nofollow">Reporters in the Field grant</a> in the last five years. The playbook’s concept and design are a result of a collaboration with the Dutch investigative journalism platform <a href="https://www.vpro.nl/argos" rel="noopener noreferrer nofollow">Argos</a>’ Ties Gijzel to create them together with Dimitris & Maria (<a href="https://loonatiks.gr/" rel="noopener noreferrer nofollow">Loonatiks Design Crew</a>) and <a href="https://yannickgregoire.nl/" rel="noopener noreferrer nofollow">Yannick Gregoire</a>. Special thanks to our partners <a href="https://journalismarena.eu/" rel="noopener noreferrer nofollow">Arena for Journalism</a>, <a href="https://hostwriter.org/" rel="noopener noreferrer nofollow">Hostwriter</a>, <a href="https://www.investigate-europe.eu/en/" rel="noopener noreferrer nofollow">Investigate Europe</a>, <a href="https://bird.tools/" rel="noopener noreferrer nofollow">BIRN & BIRD</a>, <a href="https://www.areweeurope.com/" rel="noopener noreferrer nofollow">Are We Europe</a>, <a href="https://ipi.media/" rel="noopener noreferrer nofollow">IPI</a>, the <a href="https://ejc.net" rel="noopener noreferrer nofollow">European Journalism Centre</a>, and, last but not least, <a href="https://liviu.berlin/" rel="noopener noreferrer nofollow">Liviu Barbulescu</a> for the videos, Priyanka Shankar, Ana Lankes and Marjolein Koster for their editorial support.<br />We would not have been able to kick off this project without funding by the <a href="https://www.bosch-stiftung.de/" rel="noopener noreferrer nofollow">Robert Bosch Foundation</a>.
                    </p>
                </div>
            </div>
        </section>
        <section className={`${styles.marquee}`}>
            <Marquee speed="4s"><h3>Experts & Partners</h3></Marquee>
        </section>
        <section className="container-fluid">
            <div className={`row ${styles.list}`}>
                <div className="col-9 col-md-10 col-xs-12">
                    <ul>
                        <li><a href="https://journalismarena.eu" rel="noopener noreferrer nofollow">Arena for Journalism</a></li>
                        <li><a href="https://birn.eu.com" rel="noopener noreferrer nofollow">BIRN</a></li>
                        <li><a href="https://hostwriter.org" rel="noopener noreferrer nofollow">Hostwriter</a></li>
                        <li><a href="https://www.investigate-europe.eu" rel="noopener noreferrer nofollow">Investigate Europe</a></li>
                        <li><a href="https://ipi.media" rel="noopener noreferrer nofollow">IPI</a></li>
                        <li><a href="https://ejc.net" rel="noopener noreferrer nofollow">European Journalism Centre</a></li>
                        <li><a href="https://www.vpro.nl/argos.html" rel="noopener noreferrer nofollow">Argos, VPRO / Human</a></li>
                        <li><a href="https://www.areweeurope.com" rel="noopener noreferrer nofollow">Are We Europe</a></li>
                        <li><a href="https://www.occrp.org/en" rel="noopener noreferrer nofollow">OCCRP</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className={`${styles.marquee}`}>
            <Marquee speed="4s"><h3>Resources</h3></Marquee>
        </section>
        <section className="container-fluid">
            <div className={`row ${styles.list}`}>
                <div className="col-6 col-md-6 col-xs-12">
                    <p>Several other organisations have pioneered cross-border and collaborative journalism, especially in the US. The following links and examples were helpful to us in creating this website, and could be helpful for you to explore as well: </p>
                </div>
                <div className="col-9 col-md-10 col-xs-12">
                    <ul>
                        <li><a href="https://www.routledge.com/Cross-Border-Collaborative-Journalism-A-Step-By-Step-Guide/Alfter/p/book/9781138613638" rel="noopener noreferrer nofollow">Cross-Border Collaborative Journalism A Step-By-Step Guide By Brigitte Alfter</a></li>
                        <li><a href="https://propublica.gitbook.io/collaborative/" rel="noopener noreferrer nofollow">Collaborative Data Journalism by Rachel Glickhouse</a></li>
                        <li><a href="https://collaborativejournalism.org/what-is-collaborative-journalism/" rel="noopener noreferrer nofollow">What is collaborative journalism? by Montclair State University</a></li>
                        <li><a href="https://learninglab.solutionsjournalism.org/en/courses/collaborative-playbook" rel="noopener noreferrer nofollow">Collaborative Playbook by the Solutions Journalism Learning Lab </a></li>
                        <li><a href="https://ijnet.org/en/story/cross-border-data-journalism-tips-tools-and-tricks" rel="noopener noreferrer nofollow">Cross-border data journalism tips, tools and tricks by Natalie van Hoozer</a></li>
                        <li><a href="https://blog.hostwriter.org/wp-content/uploads/2018/12/crossborderjournalism-method-final.pdf" rel="noopener noreferrer nofollow">Cross-border journalism as a method by Hostwriter </a></li>
                    </ul>
                </div>
            </div>
        </section>
        <Feedback />
    </Page>;
};

function shuffle( a ) {
    for ( let i = a.length - 1; i > 0; i-- ) {
        const j = Math.floor( Math.random() * ( i + 1 ) );
        [ a[i], a[j] ] = [ a[j], a[i] ];
    }
    return a;
}

export default AboutPage;
